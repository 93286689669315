<template>
  <div class="image">
    <slot />
  </div>
</template>

<script>
export default {
    name: "ImageComp"
}
</script>

<style>

</style>