<template>
  <div class="slide">
      <slot />
  </div>
</template>

<script>
export default {
    name: "SlideComp"
}
</script>

<style>

</style>